import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { GlobalService, VehicleService } from '../helpers/services';
import { CommonUtils } from '../helpers/utilities';
import { City } from '../types/models';

const Context = createContext<{
  cities: Array<City>;
  selectCity: any;
  setSelectCity: (value: SetStateAction<any>) => void;
}>({
  cities: [],
  selectCity: {},
  setSelectCity(value) {},
});

export function CityProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const [selectCity, setSelectCity] = useState<any>({});
  const [cities, setCities] = useState<any>([]);

  useEffect(() => {
    const initialLoad = async () => {
      const cities = await VehicleService.fetchAllCities(router.locale);
      setCities(cities);
      if (typeof window !== 'undefined') {
        const city: any = localStorage.getItem('city');
        const cityParse = JSON.parse(city);
        if (!cityParse) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                let cityRes = await GlobalService.getAutoDetectCity(
                  CommonUtils.getLanguageId(router.locale!),
                  position.coords.latitude,
                  position.coords.longitude
                );
                const detectedCity = cities.find(
                  (item) => item.City === cityRes
                );
                if (detectedCity) {
                  if (typeof detectedCity === 'number') {
                    let tempSelectedCity = cities.find(
                      (item) => item.CityId === detectedCity
                    );
                    if (!tempSelectedCity) {
                      return;
                    }
                    setSelectCity(tempSelectedCity);
                  } else {
                    setSelectCity(detectedCity);
                  }
                } else {
                  localStorage.setItem('city', JSON.stringify(cities[0]));
                  setSelectCity(cities[0]);
                }
              },
              (error) => {
                localStorage.setItem('city', JSON.stringify(cities[0]));
                setSelectCity(cities[0]);
              }
            );
          }
        } else {
          if (cities && cities.length) {
            const detectedCity = cities.find(
              (item) => item.CityId === cityParse.CityId
            );
            localStorage.setItem('city', JSON.stringify(detectedCity));
            setSelectCity(detectedCity);
          }
        }
      }
    };
    initialLoad();
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        cities,
        selectCity,
        setSelectCity,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useCityContext() {
  return useContext(Context);
}
